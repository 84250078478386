<template>
  <BasePage>
    <template #header>
      <h1 class="page-title">{{ pageTitle }}</h1>
    </template>

    <div class="container">
      <div v-if="session">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Event Session Details</h3>
            <div class="card-actions">
              <button v-if="!editMode" class="btn btn-warning" @click="editSession">Edit</button>
              <button v-if="editMode" class="btn btn-secondary" @click="cancelEdit">Cancel</button>
              <button v-if="editMode" class="btn btn-primary ms-2" @click="saveSession">Save</button>
              <button v-if="!editMode && (session.enabled === null || session.enabled === 'no')"
                      class="btn btn-success ms-2" data-bs-toggle="modal"
                      data-bs-target="#enableSessionModal" @click="prepareEnableDisable(true)">Enable</button>
              <button v-if="!editMode && session.enabled === 'yes'"
                      class="btn btn-danger ms-2" data-bs-toggle="modal"
                      data-bs-target="#disableSessionModal" @click="prepareEnableDisable(false)">Disable</button>
            </div>
          </div>
          <div class="card-body">
            <div v-if="!editMode">
              <div class="row mb-3">
                <div class="col-md-6">
                  <strong>Start Date/Time:</strong> {{ formatDateTime(session.startDateTime) }}
                </div>
                <div class="col-md-6">
                  <strong>End Date/Time:</strong> {{ formatDateTime(session.endDateTime) }}
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-6">
                  <strong>Capacity:</strong> {{ session.capacity }}
                </div>
                <div class="col-md-6">
                  <strong>Remaining Capacity:</strong> {{ remainingCapacity }}
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-6">
                  <strong>Price:</strong> £{{ (session.price / 100).toFixed(2) }}
                </div>
              </div>
            </div>
            <div v-else>
              <div class="row mb-3">
                <div class="col-md-6">
                  <label class="form-label">Start Date/Time</label>
                  <input type="datetime-local" class="form-control" v-model="editedSession.startDateTimeLocal">
                </div>
                <div class="col-md-6">
                  <label class="form-label">End Date/Time</label>
                  <input type="datetime-local" class="form-control" v-model="editedSession.endDateTimeLocal">
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-6">
                  <label class="form-label">Capacity</label>
                  <input type="number" class="form-control" v-model="editedSession.capacity">
                </div>
                <div class="col-md-6">
                  <label class="form-label">Price (in pence)</label>
                  <input type="number" class="form-control" v-model="editedSession.price">
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card mt-4">
          <div class="card-header">
            <h3 class="card-title">Registered Participants</h3>
            <div class="card-actions">
              <label class="form-check form-switch me-3">
                <input class="form-check-input" type="checkbox" v-model="showOnlyConfirmed">
                <span class="form-check-label">Show only confirmed</span>
              </label>
              <button class="btn btn-primary" @click="openRegisterModal">
                Register New Participant
              </button>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-vcenter card-table">
                <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Status</th>
                  <th>Ticket Count</th>
                  <th>Comments</th>
                  <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="participant in filteredParticipants" :key="participant.eventRegistrationId">
                  <td>{{ participant.firstName }} {{ participant.lastName }}</td>
                  <td>{{ participant.email }}</td>
                  <td>{{ participant.phone }}</td>
                  <td>
                      <span class="text-white" :class="getStatusBadgeClass(participant.status)">
                        {{ participant.status }}
                      </span>
                  </td>
                  <td>{{ participant.ticketCount }}</td>
                  <td>
                    <button v-if="participant.comments" class="btn btn-sm btn-outline-danger" @click="viewCommentsModal(participant)">
                      View
                    </button>
                    <button v-if="!participant.comments" class="btn btn-sm btn-outline-secondary" @click="addCommentsModal(participant)">
                      Add
                    </button>
                  </td>
                  <td>
                    <button class="btn btn-sm btn-primary me-2" @click="editParticipant(participant)">
                      Edit
                    </button>
                    <button v-if="participant.status === 'confirmed'" class="btn btn-sm btn-secondary" @click="resendConfirmation(participant)">
                      Resend Confirmation
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <!-- Edit Participant Modal -->
        <div class="modal fade" id="editParticipantModal" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Edit Participant</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body" v-if="editingParticipant">
                <div class="mb-3">
                  <label class="form-label">First Name</label>
                  <input type="text" class="form-control" v-model="editingParticipant.firstName">
                </div>
                <div class="mb-3">
                  <label class="form-label">Last Name</label>
                  <input type="text" class="form-control" v-model="editingParticipant.lastName">
                </div>
                <div class="mb-3">
                  <label class="form-label">Email</label>
                  <input type="email" class="form-control" v-model="editingParticipant.email">
                </div>
                <div class="mb-3">
                  <label class="form-label">Phone</label>
                  <input type="tel" class="form-control" v-model="editingParticipant.phone">
                </div>
                <div class="mb-3">
                  <label class="form-label">Status</label>
                  <select class="form-select" v-model="editingParticipant.status">
                    <option value="pending">Pending</option>
                    <option value="paid">Paid</option>
                    <option value="cancelled">Cancelled</option>
                  </select>
                </div>
                <div class="mb-3">
                  <label class="form-label">Ticket Count</label>
                  <input type="number" class="form-control" v-model="editingParticipant.ticketCount">
                </div>
                <div class="mb-3">
                  <label class="form-label">Comments</label>
                  <textarea class="form-control" v-model="editingParticipant.comments" rows="3"></textarea>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-link link-secondary" data-bs-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-primary ms-auto" @click="saveParticipant">Save changes</button>
              </div>
            </div>
          </div>
        </div>

        <!-- Register New Participant Modal -->
        <div class="modal fade" id="registerParticipantModal" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Register New Participant</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="mb-3">
                  <label class="form-label">First Name</label>
                  <input type="text" class="form-control" v-model="newParticipant.firstName">
                </div>
                <div class="mb-3">
                  <label class="form-label">Last Name</label>
                  <input type="text" class="form-control" v-model="newParticipant.lastName">
                </div>
                <div class="mb-3">
                  <label class="form-label">Email</label>
                  <input type="email" class="form-control" v-model="newParticipant.email">
                </div>
                <div class="mb-3">
                  <label class="form-label">Phone</label>
                  <input type="tel" class="form-control" v-model="newParticipant.phone">
                </div>
                <div class="mb-3">
                  <label class="form-label">Ticket Count</label>
                  <input type="number" class="form-control" v-model="newParticipant.ticketCount">
                </div>
                <div class="mb-3">
                  <label class="form-label">Comments</label>
                  <textarea class="form-control" v-model="newParticipant.comments" rows="3"></textarea>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-link link-secondary" data-bs-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-primary ms-auto" @click="registerParticipant">Register</button>
              </div>
            </div>
          </div>
        </div>

        <!-- View/Edit Comments Modal -->
        <div class="modal fade" id="commentsModal" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">{{ commentsModalMode === 'view' ? 'View Comments' : 'Edit Comments' }}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div v-if="commentsModalMode === 'view'">
                  <h6>{{ selectedParticipant?.firstName }} {{ selectedParticipant?.lastName }}</h6>
                  <p class="mt-3">{{ selectedParticipant?.comments }}</p>
                </div>
                <div v-else>
                  <div class="mb-3">
                    <label class="form-label">Comments</label>
                    <textarea class="form-control" v-model="selectedParticipant.comments" rows="5"></textarea>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-link link-secondary" data-bs-dismiss="modal">Close</button>
                <button v-if="commentsModalMode === 'view'" type="button" class="btn btn-warning" @click="editCommentsModal">Edit</button>
                <button v-if="commentsModalMode === 'edit'" type="button" class="btn btn-primary" @click="saveComments">Save</button>
              </div>
            </div>
          </div>
        </div>

        <!-- Enable Session Modal -->
        <div class="modal modal-blur fade" id="enableSessionModal" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-sm" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Enable Session</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <p>Are you sure you want to enable this session?</p>
                <p>This will make the session available for bookings.</p>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-link link-secondary" data-bs-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-success ms-auto" @click="handleEnableSession" data-bs-dismiss="modal">
                  Enable
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Disable Session Modal -->
        <div class="modal modal-blur fade" id="disableSessionModal" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-sm" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Disable Session</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <p>Are you sure you want to disable this session?</p>
                <p>This will prevent new bookings for this session.</p>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-link link-secondary" data-bs-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-danger ms-auto" @click="handleDisableSession" data-bs-dismiss="modal">
                  Disable
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="alert alert-info" role="alert">
        Loading session details... <IconInfoCircle></IconInfoCircle>
      </div>
    </div>
  </BasePage>
</template>

<script setup>
import { ref, computed, onMounted, reactive } from 'vue';
import { useRoute } from 'vue-router';
import api from '@/services/api';
import BasePage from '@/components/BasePage.vue';
import { IconInfoCircle } from "@tabler/icons-vue";

// Bootstrap import for modal interaction
let bootstrap;
onMounted(() => {
  bootstrap = window.bootstrap;
});

// State
const pageTitle = ref('Event Session');
const session = ref(null);
const participants = ref([]);
const showOnlyConfirmed = ref(false);
const editingParticipant = ref(null);
const editMode = ref(false);
const editedSession = reactive({
  startDateTime: 0,
  endDateTime: 0,
  startDateTimeLocal: '',
  endDateTimeLocal: '',
  capacity: 0,
  price: 0
});

const newParticipant = reactive({
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  ticketCount: 1,
  comments: ''
});

// Comments modal state
const selectedParticipant = ref(null);
const commentsModalMode = ref('view'); // 'view' or 'edit'

// Route
const route = useRoute();

// Computed
const remainingCapacity = computed(() => {
  if (!session.value) return 0;
  const totalTickets = participants.value.reduce((sum, p) => sum + p.ticketCount, 0);
  return session.value.capacity - totalTickets;
});

const filteredParticipants = computed(() => {
  return showOnlyConfirmed.value
      ? participants.value.filter(p => p.status === 'confirmed')
      : participants.value;
});

// Helpers
const epochToLocalDateTime = (epochSeconds) => {
  if (!epochSeconds) return '';

  const date = new Date(epochSeconds * 1000);

  // Format to YYYY-MM-DDThh:mm
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

const localDateTimeToEpoch = (localDateTime) => {
  if (!localDateTime) return 0;
  return Math.floor(new Date(localDateTime).getTime() / 1000);
};

// Methods
const formatDateTime = (timestamp) => {
  if (!timestamp) return 'N/A';
  return new Date(timestamp * 1000).toLocaleString("en-GB");
};

const getStatusBadgeClass = (status) => {
  switch (status) {
    case 'confirmed':
      return 'badge bg-success';
    case 'pending':
      return 'badge bg-warning';
    case 'cancelled':
      return 'badge bg-danger';
    default:
      return 'badge bg-secondary';
  }
};

const fetchSessionData = async (sessionId) => {
  try {
    await Promise.all([
      getSessionDetails(sessionId),
      getParticipants(sessionId),
    ]);
  } catch (error) {
    console.error('Error fetching session data:', error);
  }
};

const getSessionDetails = async (sessionId) => {
  try {
    const response = await api.get(`/api/events/session/${sessionId}`);
    session.value = response.data;

    // Set up edit session data
    editedSession.startDateTime = session.value.startDateTime;
    editedSession.endDateTime = session.value.endDateTime;
    editedSession.startDateTimeLocal = epochToLocalDateTime(session.value.startDateTime);
    editedSession.endDateTimeLocal = epochToLocalDateTime(session.value.endDateTime);
    editedSession.capacity = session.value.capacity;
    editedSession.price = session.value.price;
  } catch (error) {
    console.error('Error fetching session details:', error);
  }
};

const getParticipants = async (sessionId) => {
  try {
    const response = await api.get(`/api/events/session-participants/${sessionId}`);
    participants.value = response.data;
  } catch (error) {
    console.error('Error fetching participants:', error);
  }
};

// Participant management methods
const editParticipant = (participant) => {
  editingParticipant.value = { ...participant };
  const modal = new bootstrap.Modal(document.getElementById('editParticipantModal'));
  modal.show();
};

const saveParticipant = async () => {
  try {
    await api.put(`/api/events/session-participant/${editingParticipant.value.eventRegistrationId}`, editingParticipant.value);
    const index = participants.value.findIndex(p => p.eventRegistrationId === editingParticipant.value.eventRegistrationId);
    if (index !== -1) {
      participants.value[index] = { ...editingParticipant.value };
    }

    const modal = bootstrap.Modal.getInstance(document.getElementById('editParticipantModal'));
    modal.hide();
    editingParticipant.value = null;
  } catch (error) {
    console.error('Error updating participant:', error);
    alert('Error updating participant: ' + (error.response?.data?.error || error.message));
  }
};

const resendConfirmation = async (participant) => {
  try {
    await api.post('/api/eventRegistration/resend-confirmation', { guid: participant.guid });
    alert('Confirmation email resent successfully');
  } catch (error) {
    console.error('Error resending confirmation:', error);
    alert('Failed to resend confirmation email');
  }
};

// Comments modal methods
const viewCommentsModal = (participant) => {
  selectedParticipant.value = { ...participant };
  commentsModalMode.value = 'view';
  const modal = new bootstrap.Modal(document.getElementById('commentsModal'));
  modal.show();
};

const addCommentsModal = (participant) => {
  selectedParticipant.value = { ...participant };
  commentsModalMode.value = 'edit';
  const modal = new bootstrap.Modal(document.getElementById('commentsModal'));
  modal.show();
};

const editCommentsModal = () => {
  commentsModalMode.value = 'edit';
};

const saveComments = async () => {
  try {
    await api.put(`/api/events/session-participant/${selectedParticipant.value.eventRegistrationId}`, {
      comments: selectedParticipant.value.comments
    });

    // Update the comments in the participants array
    const index = participants.value.findIndex(p => p.eventRegistrationId === selectedParticipant.value.eventRegistrationId);
    if (index !== -1) {
      participants.value[index].comments = selectedParticipant.value.comments;
    }

    const modal = bootstrap.Modal.getInstance(document.getElementById('commentsModal'));
    modal.hide();
    selectedParticipant.value = null;
  } catch (error) {
    console.error('Error saving comments:', error);
    alert('Error saving comments: ' + (error.response?.data?.error || error.message));
  }
};

// Registration modal methods
const openRegisterModal = () => {
  // Reset form data
  newParticipant.firstName = '';
  newParticipant.lastName = '';
  newParticipant.email = '';
  newParticipant.phone = '';
  newParticipant.ticketCount = 1;
  newParticipant.comments = '';

  const modal = new bootstrap.Modal(document.getElementById('registerParticipantModal'));
  modal.show();
};

const registerParticipant = async () => {
  try {
    const response = await api.post('/api/eventRegistration/staff-registration', {
      ...newParticipant,
      sessionId: route.params.sessionId
    });

    console.log('Participant registered:', response.data);

    // Close the modal
    const modal = bootstrap.Modal.getInstance(document.getElementById('registerParticipantModal'));
    modal.hide();

    // Refresh participants list
    await getParticipants(route.params.sessionId);

    // Show a success message
    alert('Participant registered successfully');
  } catch (error) {
    console.error('Error registering participant:', error);
    // Show an error message
    alert('Failed to register participant: ' + (error.response?.data?.error || error.message));
  }
};

// Session management methods
const editSession = () => {
  editMode.value = true;
};

const cancelEdit = () => {
  editMode.value = false;
  // Reset form data
  editedSession.startDateTime = session.value.startDateTime;
  editedSession.endDateTime = session.value.endDateTime;
  editedSession.startDateTimeLocal = epochToLocalDateTime(session.value.startDateTime);
  editedSession.endDateTimeLocal = epochToLocalDateTime(session.value.endDateTime);
  editedSession.capacity = session.value.capacity;
  editedSession.price = session.value.price;
};

const saveSession = async () => {
  try {
    // Convert local datetime strings to epoch seconds
    const startDateTime = localDateTimeToEpoch(editedSession.startDateTimeLocal);
    const endDateTime = localDateTimeToEpoch(editedSession.endDateTimeLocal);

    const payload = {
      eventId: session.value.eventId,
      startDateTime: startDateTime,
      endDateTime: endDateTime,
      capacity: parseInt(editedSession.capacity),
      price: parseInt(editedSession.price)
    };

    await api.put(`/api/events/session/${route.params.sessionId}`, payload);

    // Update the session in the UI
    session.value.startDateTime = startDateTime;
    session.value.endDateTime = endDateTime;
    session.value.capacity = payload.capacity;
    session.value.price = payload.price;

    editMode.value = false;
    alert('Session updated successfully');
  } catch (error) {
    console.error('Error updating session:', error);
    alert('Failed to update session: ' + (error.response?.data?.error || error.message));
  }
};

// Enable/Disable functionality
const prepareEnableDisable = (enabling) => {
  // We don't need to do anything complex here since we're using static modals
  console.log(`Preparing to ${enabling ? 'enable' : 'disable'} session`);
};

const handleEnableSession = async () => {
  try {
    await api.post(`/api/events/session/enable/${route.params.sessionId}`);

    // Update the local session data
    session.value.enabled = 'yes';

    alert('Session enabled successfully');
  } catch (error) {
    console.error('Error enabling session:', error);
    alert('Failed to enable session: ' + (error.response?.data?.error || error.message));
  }
};

const handleDisableSession = async () => {
  try {
    await api.post(`/api/events/session/disable/${route.params.sessionId}`);

    // Update the local session data
    session.value.enabled = 'no';

    alert('Session disabled successfully');
  } catch (error) {
    console.error('Error disabling session:', error);
    alert('Failed to disable session: ' + (error.response?.data?.error || error.message));
  }
};

// Lifecycle hooks
onMounted(() => {
  fetchSessionData(route.params.sessionId);
});
</script>