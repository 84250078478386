<template>
  <form @submit.prevent="handleSubmit">
    <div class="card">
      <div class="card-header">
        <h3 v-if="!existingEvent" class="card-title">Add New Event</h3>
        <h3 v-if="existingEvent" class="card-title">{{ event.title }} - #{{ event.eventId }}</h3>
        <div v-if="existingEvent" class="card-options">
          <button v-if="!editMode" class="btn btn-warning" @click="editEvent(true)">Edit</button>
          <button v-if="editMode" class="btn btn-secondary" @click="editEvent(false)">Cancel</button>
          <button v-if="!editMode && (event.enabled === null || event.enabled === 'no')"
                  class="btn btn-success ms-2" data-bs-toggle="modal"
                  data-bs-target="#enableEventModal" @click="prepareEnableDisable(true)">Enable</button>
          <button v-if="!editMode && event.enabled === 'yes'"
                  class="btn btn-danger ms-2" data-bs-toggle="modal"
                  data-bs-target="#disableEventModal" @click="prepareEnableDisable(false)">Disable</button>
        </div>
      </div>
      <div class="card-body">
        <div v-if="formError" class="alert alert-danger mb-3">
          {{ formError }}
        </div>

        <div class="row mb-3">
          <!-- Event Title -->
          <div class="col-md-6">
            <label class="form-label">Event Title</label>
            <input v-model="event.title" type="text" class="form-control" required :disabled="!editMode"/>
          </div>
          <!-- Slug -->
          <div class="col-md-6">
            <label class="form-label">Slug
              <IconInfoCircle
                  class="cursor-pointer"
                  style="margin-top: -9px"
                  data-bs-toggle="modal"
                  data-bs-target="#toolTipModal"
                  @click="toolTipMessage = 'This can only contain lowercase letters and hyphens, this is used for the URL. e.g. https://example.com/events/my-event-slug'">
              </IconInfoCircle>
            </label>
            <input v-model="event.slug" type="text" class="form-control" :disabled="!editMode" required
                   @input="validateSlugFormat"/>
            <small v-if="!slugIsValid && editMode" class="text-danger">
              Slug can only contain lowercase letters, numbers, and hyphens
            </small>
            <small v-else class="text-muted">
              Must be unique - only lowercase letters, numbers, and hyphens allowed
            </small>
          </div>
        </div>

        <!-- Description -->
        <div class="mb-3">
          <label class="form-label">Description</label>
          <textarea :disabled="!editMode" v-model="event.description" class="form-control" required></textarea>
        </div>

        <div class="row mb-3">
          <!-- Price -->
          <div class="col-md-6">
            <label class="form-label">Price (pence)</label>
            <input :disabled="!editMode" v-model="event.price" type="number" class="form-control" required/>
          </div>

          <!-- Capacity -->
          <div class="col-md-6">
            <label class="form-label">Capacity</label>
            <input :disabled="!editMode" v-model="event.capacity" type="number" class="form-control" required/>
          </div>
        </div>

        <div class="row mt-6">
          <div class="card">
            <div class="row row-0">
              <div class="col-3">
                <img width="320" height="240" v-if="event.image" :src="event.image"/>
                <svg xmlns="http://www.w3.org/2000/svg" v-if="!event.image" class="w-100"
                     preserveAspectRatio="none" width="320" height="240" viewBox="0 0 400 200"
                     fill="transparent" stroke="var(--tblr-border-color, #b8cef1)">
                  <line x1="0" y1="0" x2="400" y2="200"></line>
                  <line x1="0" y1="200" x2="400" y2="0"></line>
                </svg>
              </div>
              <div class="col">
                <div class="card-body">
                  <div class="form-label">Event Image</div>
                  <div class="d-flex">
                    <input :disabled="!editMode" type="file" class="form-control flex-grow-1 me-2"
                           @change="onFileChange"/>
                    <div :disabled="!editMode" class="btn btn-primary" @click="uploadImage">Upload</div>
                  </div>
                  <div v-if="uploadStatus" class="alert alert-azure mt-2">
                    {{ uploadStatus }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer text-end">
        <button :disabled="!editMode || !slugIsValid" type="submit" class="btn btn-primary">Submit</button>
      </div>
    </div>
  </form>

  <div class="card mt-5" v-if="existingEvent">
    <div class="card-header">
      <h3 class="card-title">Event Sessions</h3>
      <div class="card-options">
        <button class="btn btn-primary" @click="addSession">Add New Session</button>
      </div>
    </div>
    <div class="card-body">
      <table class="table">
        <thead>
        <tr>
          <th scope="col">Start Date/Time</th>
          <th scope="col">End Date/Time</th>
          <th scope="col">Capacity</th>
          <th scope="col">Price (pence)</th>
          <th scope="col" class="w-1 text-end text-nowrap"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(session, index) in eventSessions" :key="index">
          <td v-if="!session.isNew">{{ formatDateTime(session.startDateTime) }}</td>
          <td v-if="!session.isNew">{{ formatDateTime(session.endDateTime) }}</td>
          <td v-if="!session.isNew">{{ session.capacity }}</td>
          <td v-if="!session.isNew">{{ session.price }}</td>
          <td v-if="session.isNew">
            <input v-model="session.startDateTime" type="datetime-local" class="form-control" />
          </td>
          <td v-if="session.isNew">
            <input v-model="session.endDateTime" type="datetime-local" class="form-control" />
          </td>
          <td v-if="session.isNew">
            <input type="number" v-model="session.capacity" placeholder="Capacity" class="form-control" />
          </td>
          <td v-if="session.isNew">
            <input type="number" v-model="session.price" placeholder="Price" class="form-control" />
          </td>
          <td class="text-end text-nowrap">
            <router-link :to="`/events/session/${session.eventSessionId}`" class="btn btn-icon btn-ghost-cyan"><IconInfoCircle></IconInfoCircle></router-link>
            <button v-if="session.isNew" class="btn btn-icon btn-ghost-success" @click="saveSession(index)">
              <IconDeviceFloppy></IconDeviceFloppy>
            </button>
            <button v-else class="btn btn-icon btn-ghost-danger" data-bs-toggle="modal"
                    data-bs-target="#deleteSessionModal" @click="askToDeleteSession(session)">
              <IconTrash></IconTrash>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

  <DangerModal
      ref="modalDeleteSession"
      modalId="deleteSessionModal"
      title="Delete Session"
      :message="deleteMessage"
      closeButton="Cancel"
      submitButton="Delete"
      @submit="handleSessionDelete"
      :errorMessage="deleteErrorMessage"
  ></DangerModal>

  <!-- Enable Event Modal -->
  <DangerModal
      ref="modalEnableEvent"
      modalId="enableEventModal"
      title="Enable Event"
      :message="enableDisableMessage"
      closeButton="Cancel"
      submitButton="Enable"
      submitClass="btn-success"
      @submit="handleEnableEvent"
      :errorMessage="enableDisableErrorMessage"
  ></DangerModal>

  <!-- Disable Event Modal -->
  <DangerModal
      ref="modalDisableEvent"
      modalId="disableEventModal"
      title="Disable Event"
      :message="enableDisableMessage"
      closeButton="Cancel"
      submitButton="Disable"
      submitClass="btn-danger"
      @submit="handleDisableEvent"
      :errorMessage="enableDisableErrorMessage"
  ></DangerModal>

  <InfoModal modal-id="toolTipModal" :message="toolTipMessage"></InfoModal>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { IconInfoCircle, IconDeviceFloppy, IconTrash } from "@tabler/icons-vue";
import api from '@/services/api';
import InfoModal from "@/components/InfoModal.vue";
import DangerModal from "@/components/DangerModal.vue";

// State variables
const event = reactive({
  title: '',
  slug: '',
  description: '',
  price: null,
  capacity: null,
  image: '',
  enabled: null,
});

const editMode = ref(true);
const existingEvent = ref(false);
const eventSessions = ref([]);
const eventId = ref(null);
const selectedFile = ref(null);
const uploadStatus = ref('');
const toolTipMessage = ref('');
const deleteMessage = ref('');
const deleteErrorMessage = ref('');
const sessionToDelete = ref(null);
const formError = ref('');
const slugIsValid = ref(true);

// New state for enable/disable functionality
const enableDisableMessage = ref('');
const enableDisableErrorMessage = ref('');
const isEnabling = ref(false);

// Component references
const modalDeleteSession = ref(null);
const modalEnableEvent = ref(null);
const modalDisableEvent = ref(null);

// Get route and router
const route = useRoute();
const router = useRouter();

onMounted(async () => {
  if (route.params.eventId) {
    existingEvent.value = true;
    eventId.value = route.params.eventId;
    editMode.value = false;
    await getExistingEvent();
  } else {
    document.title = "Add New Event | CarneCloud";
  }
});

// Methods
const editEvent = (value) => {
  editMode.value = value;
  formError.value = ''; // Clear any previous errors
  if (!value) {
    // If canceling edit, revert to original data
    getExistingEvent();
  }
};

const validateSlugFormat = () => {
  // Allow only lowercase letters, numbers, and hyphens
  const slugRegex = /^[a-z0-9-]+$/;
  slugIsValid.value = slugRegex.test(event.slug);
};

const onFileChange = (event) => {
  selectedFile.value = event.target.files[0];
};

const uploadImage = async () => {
  if (!selectedFile.value) {
    uploadStatus.value = 'No file selected';
    return;
  }

  const formData = new FormData();
  formData.append('image', selectedFile.value);
  try {
    const response = await api.post('/api/dam/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    uploadStatus.value = 'Upload successful: ' + response.data.url;
    event.image = response.data.url;
  } catch (error) {
    uploadStatus.value = 'Upload failed: ' + (error.response?.data.error || error.message);
  }
};

const addSession = () => {
  eventSessions.value.unshift({
    eventSessionId: null,
    eventId: parseInt(eventId.value),
    startDateTime: '',
    endDateTime: '',
    capacity: event.capacity,
    price: event.price,
    isNew: true
  });
};

const saveSession = async (index) => {
  const session = eventSessions.value[index];
  if (session.startDateTime && session.endDateTime && session.capacity && session.price) {
    try {
      const payload = {
        eventId: eventId.value,
        // Convert milliseconds to seconds by dividing by 1000
        startDateTime: Math.floor(typeof session.startDateTime === 'number'
            ? session.startDateTime / 1000
            : new Date(session.startDateTime).getTime() / 1000),
        endDateTime: Math.floor(typeof session.endDateTime === 'number'
            ? session.endDateTime / 1000
            : new Date(session.endDateTime).getTime() / 1000),
        capacity: parseInt(session.capacity),
        price: parseInt(session.price)
      };

      const response = await api.post('/api/events/session', payload);
      console.log('Session added:', response.data);
      await getEventSessions();
    } catch (error) {
      console.error('Error posting session:', error);
    }
  }
};

const askToDeleteSession = (session) => {
  deleteMessage.value = `Are you sure you want to delete this session?`;
  sessionToDelete.value = session.eventSessionId;
};

const handleSessionDelete = async () => {
  if (sessionToDelete.value) {
    try {
      await api.delete(`/api/events/session/${sessionToDelete.value}`);
      await getEventSessions();
      modalDeleteSession.value.$refs.closeButton.click();
      sessionToDelete.value = null;
      deleteMessage.value = '';
      deleteErrorMessage.value = '';
    } catch (e) {
      deleteErrorMessage.value = e.message;
    }
  }
};

const getEventSessions = async () => {
  try {
    const response = await api.get(`/api/events/sessions/all/${eventId.value}`);
    eventSessions.value = response.data;
  } catch (error) {
    console.error('Error fetching event sessions:', error);
  }
};

const getExistingEvent = async () => {
  try {
    const [eventResp, sessionResp] = await Promise.all([
      api.get(`/api/events/${eventId.value}`),
      api.get(`/api/events/sessions/all/${eventId.value}`)
    ]);

    // Update the reactive object by assigning each property
    Object.assign(event, eventResp.data);
    eventSessions.value = sessionResp.data;

    // Make sure we track the enabled property
    console.log('Event data:', eventResp.data);

    document.title = `${event.title} | CarneCloud`;

    // Validate slug after loading
    validateSlugFormat();
  } catch (error) {
    console.error('Error fetching event data:', error);
  }
};

const handleSubmit = async () => {
  // Clear previous errors
  formError.value = '';

  // Revalidate slug format before submission
  validateSlugFormat();
  if (!slugIsValid.value) {
    formError.value = 'Slug can only contain lowercase letters, numbers, and hyphens.';
    return;
  }

  if (existingEvent.value) {
    if (editMode.value) {
      try {
        await api.put(`/api/events/${eventId.value}`, event);
        editMode.value = false;
        await getExistingEvent();
      } catch (error) {
        if (error.response && error.response.data.error) {
          formError.value = error.response.data.error;
        } else {
          formError.value = 'Error updating event: ' + (error.message || 'Unknown error');
        }
        console.error('Error updating event:', error.response ? error.response.data : error.message);
      }
    }
  } else {
    try {
      const response = await api.post('/api/events', event);
      console.log('Event created with ID:', response.data.eventId);
      router.push('/events/');
    } catch (error) {
      if (error.response && error.response.data.error) {
        formError.value = error.response.data.error;
      } else {
        formError.value = 'Error creating event: ' + (error.message || 'Unknown error');
      }
      console.error('Error creating event:', error.response ? error.response.data : error.message);
    }
  }
};

const formatDateTime = (timestamp) => {
  return new Date(timestamp * 1000).toLocaleString("en-GB");
};

// Enable/Disable functions
const prepareEnableDisable = (enabling) => {
  isEnabling.value = enabling;
  enableDisableMessage.value = `Are you sure you want to ${enabling ? 'enable' : 'disable'} event "${event.title}"?`;
  enableDisableErrorMessage.value = '';
};

const handleEnableEvent = async () => {
  try {
    await api.post(`/api/events/enable/${eventId.value}`);
    await getExistingEvent();
    modalEnableEvent.value.$refs.closeButton.click();
    enableDisableErrorMessage.value = '';
  } catch (error) {
    enableDisableErrorMessage.value = error.response?.data?.error || error.message;
  }
};

const handleDisableEvent = async () => {
  try {
    await api.post(`/api/events/disable/${eventId.value}`);
    await getExistingEvent();
    modalDisableEvent.value.$refs.closeButton.click();
    enableDisableErrorMessage.value = '';
  } catch (error) {
    enableDisableErrorMessage.value = error.response?.data?.error || error.message;
  }
};
</script>